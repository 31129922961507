import React from "react"
import { Layout, Head, Breadcrumbs, BlogSlide } from "@common"
import { NewsletterSection } from "@sections"
import "./styles/blog.scss"

const Blog = ({ pageContext }) => {
  const { posts, page } = pageContext
  return (
    <Layout title={"ŚWIĄTYNIA WIEDZY DJINNA"} parallaxImg={page.featuredImage}>
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="blog">
        <div className="blog-content">
          <div className="container">
            <Breadcrumbs elements={[{ label: "Wiedza", url: "/blog/" }]} />
            <div className="row blog-slider">
              {posts.map((el, k) => {
                return (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={k}>
                    <BlogSlide {...el} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <NewsletterSection />
    </Layout>
  )
}

export default Blog
