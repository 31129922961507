import React from "react"
import { Mandala } from "@common"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const CallContact = ({
  title = "Umów się na konsultację",
  buttonText = "Skontaktuj się z terapeutą",
  url,
}) => {
  return (
    <div className="contact">
      <Mandala height={250}>
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="title">{title}</h2>
          </div>
          <div className="row justify-content-center">
            <a
              className="button"
              href={"/kontakt"}
              onClick={() => {
                trackCustomEvent({
                  category: "Contact button - Skontaktuj się z terapeutą",
                  action: "Click",
                  label: "Skontaktuj się z terapeutą",
                })
              }}
            >
              {buttonText}
            </a>
          </div>
        </div>
      </Mandala>
    </div>
  )
}

export default CallContact
