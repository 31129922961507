import React from "react"
import { Link } from "gatsby"
import { BlogSlider, Mandala } from "@common"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const BlogSection = ({
  title = "W Świątyni Wiedzy",
  posts = [],
  buttonText = "Poczytaj o CBD",
}) => {
  return (
    <React.Fragment>
      <div className="blog">
        <div className="container">
          <div className="row justify-content-center">
            <h1>{title}</h1>
          </div>
          <BlogSlider data={posts} />
        </div>
      </div>
      <Mandala height={250}>
        <div className="container">
          <div className="row justify-content-center">
            <Link
              className="button"
              to={"/blog/"}
              onClick={() => {
                trackCustomEvent({
                  category: "Blog Button - Poczytaj o CBD",
                  action: "Click",
                  label: "Poczytaj o CBD",
                })
              }}
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </Mandala>
    </React.Fragment>
  )
}
export default BlogSection
